i.twitter {
    background: url("./images/x.png") no-repeat 0px 0px;
    background-position: none;
}

i.instagram {
    background: url("./images/instagram.png") no-repeat 0px 0px;
    background-position: none;
    width: 29px;
    height: 29px;
}